(function() {
  window.dataLayer = window.dataLayer || [];
  
  const formEvents = {
    "/" : "Bulletin sign up",
    "/contact/" : "Contact us"
  }

  window.addEventListener('message', event => {
    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
      const gtmData = {};
      const { id: formID, data: formData} = event.data;
      const { pathname } = event.source.location;

      gtmData["event"] = formEvents[pathname];
      gtmData["id"] = formID;

      formData.forEach(formObj => {
        gtmData[formObj.name] = formObj.value;
      });

      dataLayer.push(gtmData);
    }
  });

  var domainsToDecorate = [
      'pugpig.com', //add or remove domains (without https or trailing slash)
    ],

    queryParams = [

      'utm_medium', //add or remove query parameters you want to transfer
      'utm_source',
      'utm_campaign',
      'utm_content',
      'utm_term'
    ]

  var links = document.querySelectorAll('a');

  // check if links contain domain from the domainsToDecorate array and then decorates

  for (var linkIndex = 0; linkIndex < links.length; linkIndex++) {

    for (var domainIndex = 0; domainIndex < domainsToDecorate.length; domainIndex++) {

      if (links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 && links[linkIndex].href.indexOf("#") === -1) {

        links[linkIndex].href = decorateUrl(links[linkIndex].href);

      }

    }

  }

  // decorates the URL with query params

  function decorateUrl(urlToDecorate) {

    const queryString = window.location.search;

    if (queryString) {
    
      urlToDecorate = (urlToDecorate.indexOf('?') === -1) ? urlToDecorate + '?' : urlToDecorate + '&';

      var collectedQueryParams = [];

      for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {

        if (getQueryParam(queryParams[queryIndex])) {

          collectedQueryParams.push(queryParams[queryIndex] + '=' + getQueryParam(queryParams[queryIndex]))

        }

      }

      urlToDecorate = urlToDecorate + collectedQueryParams.join('&');
    } 
    
    return urlToDecorate;

  }

  function getQueryParam(name) {

    if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))

      return decodeURIComponent(name[1]);

  }

})();